<template>
  <div class="req-temp-container mt-6">
    <vs-row class="mt-5">
      <vs-card class="fix-pads">
        <div slot="header">
          <div class="flex justify-between items-center">
            <h3 class="font-normal lg:text-2xl md:text-xl text-lg">Logo library</h3>
          </div>
        </div>
        <div>
          <div class="flex upload-img mb-8 md:mb-16 justify-end">
            <input type="file" name="image" v-validate="{ size: maxFileSize }" class="hidden" ref="uploadLogoInput" @change="updateLogoImg" accept="image/png" />
            <div class="force-btn-width">
              <vs-button v-round class="only-border-btn ml-6" size="medium" @click="$refs.uploadLogoInput.click()">
                <upload-icon size="1.5x" class="custom-class mr-2"></upload-icon>Upload Image
              </vs-button>
            </div>
          </div>
          <div>
            <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 md:gap-6">
              <div v-for="(logo, index) in logoList" :key="index" class="logo-gallery-wrap">
                <div class="logo-gallery-img p-5">
                  <S3ImageGenerator :document="logo.logoImage" :key="logo.logoImage"/>
                  <span class="XCircleIcon">
                    <feather-icon icon="Edit2Icon" @click="editLogo(logo.logoId)" class="cursor-pointer edit-icon" style="vertical-align: bottom" />
                  </span>
                </div>
                <div class="company-logo-caption py-2 md:py-4">
                  <p class="text-sm">{{ logo.logoId }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vs-card>
    </vs-row>
    <vs-popup class="vs-popup" size="medium" :title="popupTitle" :active.sync="popupActive">
      <div class="upload-img mt-5 mb-8 lg:mb-14" >
        <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div class="logo-gallery-img relative" v-if="image">
            <S3ImageGenerator :document="image" :is-base64="isBase64(image)" :key="image" />
            <span class="XCircleIcon">
              <feather-icon icon="XCircleIcon" @click="clearImage" class="ml-1" />
            </span>
          </div>
          <div class="logo-gallery-img py-16 text-sm" v-else> Preview </div>
          <div class="flex items-center justify-center">
            <vs-button v-round class="only-border-btn" size="medium" @click="$refs.uploadLogoInput.click()">
              <upload-icon size="1.5x" class="custom-class mr-2" />Upload logo
            </vs-button>
          </div>
        </div>
      </div>
      <div class="flex justify-start w-full pb-4 md:pb-6" >
        <span v-show="errors.has(`fileType`)" class="text-danger text-sm block">{{ errors.first(`fileType`) }}</span> <br>
        <span v-show="errors.has(`image`)" class="text-danger text-sm block">{{ errors.first(`image`) }}</span>
      </div>
      <div class="flex justify-end w-full pt-4 pb-4 md:pb-6">
        <div class="actions text-right">
          <vs-button v-round class="only-border-btn" v-if="editMode" @click="deleteLogo" size="medium">Remove</vs-button>
          <vs-button size="medium" class="ml-5" @click="save" v-round :disabled="errors.items.length > 0 || !image || !logo">Save</vs-button>
          <a @click="onCancel" class="ml-8 text-button text-sm text-button">Cancel</a>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import { EditIcon, UploadIcon } from "vue-feather-icons";
import { mapActions } from "vuex";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
export default {
  components: {
    EditIcon,
    S3ImageGenerator,
    UploadIcon,
  },
  data() {
    return {
      logo: "",
      image: "",
      popupActive: false,
      logoList: [],
      popupTitle: "Upload Logo",
      editMode: false,
      logoId: "",
      maxFileSize: "",
    };
  },
  methods: {
    ...mapActions("logo", ["fetchLogo", "removeLogo", "saveLogo"]),
    ...mapActions("admin", ["checkLogin"]),
    updateLogoImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
        this.logo = input.target.files[0];
        this.popupActive = true;
        this.logoValidation(this.logo);
      }
    },
    editLogo(logoId) {
      this.popupTitle = "Replace Logo";
      this.popupActive = true;
      this.editMode = true;
      const logoIndex = this.logoList.findIndex((logo) => logo.logoId == logoId);
      const logo = this.logoList[logoIndex];
      this.logoId = logo.logoId;
      this.image = logo.logoImage;
    },
    logoValidation(logo) {
      this.errors.clear();
      if (logo.type !== 'image/png') {
        this.errors.add({
          field: 'fileType',
          msg: 'Please ensure file is a PNG image'
        });
      }
    },
    clearImage() {
      this.errors.clear();
      this.image = null;
      this.$refs.uploadLogoInput.value = null;
      this.logo = null;
    },
    onCancel(){
      this.popupActive = false;
      this.image = null;
      this.logo = null;
      this.$refs.uploadLogoInput.value = null;
      this.errors.clear();
      this.editMode = false;
      this.popupTitle = "Upload Logo";
    },
    isBase64(key) {
      if (typeof key !== 'string') {
        return false;
      }
      const validation = new RegExp(/data:image/);
      return validation.test(key);
    },
    async checkAdminLogin() {
      await this.checkLogin().then((res) => {
        this.maxFileSize = res.data.data.maxLogoSize;
      });
    },
    async save() {
      this.$vs.loading();
      const formData = new FormData();
      formData.append("introducerId", this.introducerId);
      formData.append('logoFile', this.logo);
      if (this.editMode) {
        formData.append("logoId", this.logoId);
        formData.append("editMode", this.editMode);
      }
      const payloadObj = {
        data: formData,
        config: {
          header: {
            "Content-Type": "multipart/form-data",
          },
        },
      };
      await this.saveLogo(payloadObj).then((result) => {
        this.logoList = result.data.data;
        this.onCancel();
        this.$vs.loading.close();
        this.editMode = false;
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: ex.data.title,
          text: ex.data.message,
          color: "danger",
          position: "top-right",
        });
      });
    },
    getLogo() {
      this.$vs.loading();
      this.fetchLogo(this.introducerId).then((response) => {
        this.logoList = response.data.data;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: ex.data.title,
          text: ex.data.message,
          color: "danger",
          position: "top-right",
        });
      });
    },
    deleteLogo() {
      this.$vs.loading();
      const data = {
        logoId: this.logoId,
        introducerId: this.introducerId,
      };
      this.removeLogo(data).then((response) => {
        this.logoList = response.data.data;
        this.onCancel();
        this.$vs.loading.close();
        this.editMode = false;
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: ex.data.title,
          text: ex.data.message,
          color: "danger",
          position: "top-right",
        });
      })
    }
  },
  mounted() {
    this.checkAdminLogin();
    this.getLogo();
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    introducerId() {
      return this.user.userRole === "superAdmin" ? this.user._id : this.user.introducer;
    },
  },
};
</script>